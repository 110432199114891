var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',[_c('div',{class:[
      'bg-cover fixed top-0 right-0 bottom-0 left-0 font-sans antialiased leading-normal',
      _vm.nightMode ? 'text-gray-100' : 'text-gray-900',
      _vm.nightMode ? 'text-gray-100' : 'text-gray-900'
    ],style:({
      backgroundImage: ("url('" + (_vm.data.theme.backgroundImage.responsiveImage.base64) + "')")
    })},[_c('div',{staticClass:"bg-cover fixed top-0 right-0 bottom-0 left-0",style:({
        backgroundImage: ("url('" + (_vm.data.theme.backgroundImage.url) + "')")
      })},[_c('div',{staticClass:"max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0"},[_c('div',{class:[
            'w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl opacity-75 mx-6 lg:mx-0',
            _vm.nightMode ? 'bg-gray-900' : 'bg-white'
          ],attrs:{"id":"profile"}},[_c('div',{staticClass:"p-4 md:p-12 text-center lg:text-left"},[_c('div',{staticClass:"block lg:hidden"},[_c('datocms-image',{staticClass:"rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center",attrs:{"data":_vm.data.profile.photo.mobileImage}})],1),_c('h1',{staticClass:"text-3xl font-bold pt-8 lg:pt-0"},[_vm._v(" "+_vm._s(_vm.data.profile.name)+" ")]),_c('div',{class:[
                'mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 opacity-25',
                ("border-" + (_vm.data.theme.color) + "-500")
              ]}),_c('p',{staticClass:"pt-4 text-base font-bold flex items-center justify-center lg:justify-start"},[_c('span',{staticClass:"pr-4"},[_c('font-awesome-icon',{class:[
                    'h-4 fill-current',
                    ("text-" + (_vm.data.theme.color) + "-700")
                  ],attrs:{"icon":"briefcase"}})],1),_vm._v(" "+_vm._s(_vm.data.profile.profession)+" ")]),_c('p',{staticClass:"pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start"},[_c('span',{staticClass:"pr-4"},[_c('font-awesome-icon',{class:[
                    'h-4 fill-current',
                    ("text-" + (_vm.data.theme.color) + "-700")
                  ],attrs:{"icon":"globe"}})],1),_vm._v(" "+_vm._s(_vm.data.profile.location)+" - "+_vm._s(_vm.data.profile.coordinates.latitude)+"° N, "+_vm._s(_vm.data.profile.coordinates.longitude)+"° W ")]),_c('p',{staticClass:"pt-8 text-sm"},[_vm._v(" "+_vm._s(_vm.data.profile.description)+" ")]),_c('div',{staticClass:"pt-12 pb-8"},[_c('a',{class:[
                  'text-white font-bold py-2 px-4 rounded-full',
                  ("bg-" + (_vm.data.theme.color) + "-700"),
                  ("hover:bg-" + (_vm.data.theme.color) + "-900")
                ],attrs:{"href":("mailto:" + (_vm.data.profile.email))}},[_vm._v(" Get In Touch ")])]),_c('div',{staticClass:"mt-6 pb-16 lg:pb-0 w-4/5 lg:w-full mx-auto flex flex-wrap items-center justify-center lg:justify-start"},_vm._l((_vm.data.socials),function(profile){return _c('a',{key:profile.social,staticClass:"link",attrs:{"href":profile.url}},[_c('font-awesome-icon',{class:[
                    'fill-current text-gray-600 ml-3 mr-3 lg:ml-0 lg:mr-5',
                    ("hover:text-" + (_vm.data.theme.color) + "-700")
                  ],attrs:{"icon":['fab', profile.social],"size":"2x"}})],1)}),0)])]),_c('div',{staticClass:"w-full lg:w-2/5"},[_c('datocms-image',{staticClass:"rounded-none lg:rounded-lg shadow-2xl hidden lg:block",attrs:{"data":_vm.data.profile.photo.desktopImage}})],1),_c('div',{staticClass:"absolute top-0 right-0 h-12 w-18 p-4"},[_c('button',{staticClass:"js-change-theme focus:outline-none",on:{"click":_vm.toggleDayNight}},[_vm._v(" "+_vm._s(_vm.nightMode ? "☀️" : "🌙")+" ")])])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }